<template>
  <div>
    <div class="block banner bg pdns">
      <div class="container">
        <div class="box">
          <h1>{{$t('productDns')}}</h1>
          <p>{{$t('product27')}}</p>
          <p class="mobile des">{{$t('product28')}}</p>
          <el-button type="primary">{{$t('consultingBusiness')}}</el-button>
        </div>
      </div>
    </div>
    <div class="block block1 pc">
      <div class="container">
        <el-row>
          <el-col :span="17">
            <h2>{{$t('product29')}}</h2>
            <p>{{$t('product28')}}</p>
            <p>{{$t('product30')}}</p>
          </el-col>
          <el-col :span="7">
            <OtherList hideRoute="productdns" />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <h2>{{$t('product31')}}</h2>
        <ul class="list1">
          <li class="icon18">
            <h5>{{$t('product32')}}</h5>
            <p>{{$t('product33')}}</p> 
          </li>
          <li class="icon47">
            <h5>{{this.$t('home52')}}</h5>
            <p>{{$t('product34')}}</p> 
          </li>
          <li class="icon46">
            <h5>{{$t('product35')}}</h5>
            <p>{{$t('product36')}}</p>  
          </li>
          <li class="icon49">
            <h5>{{$t('product37')}}</h5>
            <p>{{$t('product38')}}</p>  
          </li>
          <li class="icon48">
            <h5>{{$t('product39')}}</h5>
            <p>{{$t('product40')}}</p>  
          </li>
          <li class="icon42">
            <h5>{{$t('home14')}}</h5>
            <p>{{$t('product41')}}</p>  
          </li>
        </ul>
      </div>
    </div>
    <div class="block">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="img"><img src="@/assets/images/image14.svg" /></div>
            <div class="cont">
              <h4>{{$t('product42')}}</h4>
              <p>{{$t('product43')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block gray">
      <div class="container">
        <ul class="product-list">
          <li class="left">
            <div class="img"><img src="@/assets/images/image15.svg" /></div>
            <div class="cont">
              <h4>{{$t('product44')}}</h4>
              <p>{{$t('product45')}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="block ac">
      <div class="container">
        <ul class="product-list">
          <li>
            <div class="cont">
              <h4>{{$t('product46')}}</h4>
              <p>{{$t('product47')}}</p>
            </div>
          </li>
        </ul>
      </div>
      <Map :hide='true' />
      <div class="container">
        <ApplyBox />
      </div>
    </div>
  </div>
</template>
<script>
  import OtherList from '@/components/OtherList.vue'
  import ApplyBox from '@/components/ApplyBox.vue'
  import Map from '@/components/Map.vue'


export default {
  name: "ProductDns",
  components: {
    OtherList,
    ApplyBox,
    Map
  },
  mounted() {
  },
  data() {
    return {
    }
  },
  metaInfo() {
    return {
      title: '「BoxCDN」'+this.$t('productDns')+'-'+ this.$t('home80')
    }
  }
}
</script>
<style lang="scss" scoped>
  .banner {
    background-image: url('~@/assets/images/banner2.jpg');
  }

  .list1 {
    li {
      width: 33.33%;
    }
  }
</style>
